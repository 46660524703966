<template>
  <Layout :title="'공간상세정보'" :hasMenu="true" :link="'PlaceList'">
    <div class="container">
      <div class="content-wrapper flex flex-row justify-between">
        <div class="content-left-wrapper flex flex-col">
          <div class="content-box content-box-1">
            <PlaceSummaryInfo :data="data" :hasButton="true" :onChat="onChat" />
          </div>

          <div class="content-box content-box-2">
            <div class="header flex flex-row items-center justify-start">
              <img src="@/assets/info.png" />
              <p class="title">경쟁임차</p>
            </div>

            <div class="item flex flex-row items-center justify-between">
              <span>월 임대료</span>
              <p>{{ leasePrice.toLocaleString() }}만원</p>
            </div>

            <div class="item flex flex-row items-center justify-between">
              <span>보증금</span>
              <p>{{ utilities.toLocaleString() }}만원</p>
            </div>

            <div
              v-if="maxPrice && minPrice !== maxPrice"
              class="item flex flex-row items-center justify-between"
            >
              <span>최고가</span>
              <p>{{ maxPrice.toLocaleString() }}원</p>
            </div>

            <div class="item flex flex-row items-center justify-between">
              <span>참여인원</span>
              <p>{{ offers.length }}명</p>
            </div>

            <div class="w-full button-group flex flex-col">
              <button
                @click="goToJoin"
                class="flex-1 flex justify-center items-center"
              >
                <eva-icon
                  class="mr-1"
                  name="diagonal-arrow-left-up-outline"
                  :width="20"
                  :height="20"
                  :fill="'#fff'"
                />
                경쟁임차 참여하기
              </button>
            </div>
          </div>

          <div
            v-if="data.hasPlaceDetail"
            class="content-box flex-1 content-box-3"
          >
            <div v-if="data.subTitle" class="item">
              <span>* 임대인의 의견</span>
              <div class="item-content">
                {{ data.subTitle }}
              </div>
            </div>

            <div v-if="data.seller" class="item">
              <span>* 중개/에이전트</span>
              <div class="item-content">
                {{ data.seller }}
              </div>
            </div>

            <div class="item">
              <span>* 상세사항</span>
              <div class="item-content">
                <div
                  v-if="data.hasPlaceClassification"
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">분류</span>
                  <p class="flex-1">{{ data.hasPlaceClassification.title }}</p>
                </div>

                <div class="item-content-sub flex flex-row items-center">
                  <span class="flex-1">임대평수</span>
                  <p class="flex-1">
                    {{ leasableArea }}평 ({{ leasableArea * 3.3 }}m<sup>2</sup>)
                  </p>
                </div>

                <div
                  v-if="data.hasPlaceDetail"
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">해당층</span>
                  <p class="flex-1">
                    {{ data.hasPlaceDetail.buildingFloor }}층
                  </p>
                </div>

                <div
                  v-if="
                    data.hasPlaceDetail &&
                      data.hasPlaceDetail.bathroomCnt !== null
                  "
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">화장실</span>
                  <p class="flex-1">{{ data.hasPlaceDetail.bathroomCnt }}개</p>
                </div>

                <div
                  v-if="
                    data.hasPlaceDetail &&
                      data.hasPlaceDetail.parkingCnt !== null
                  "
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">주차댓수</span>
                  <p class="flex-1">{{ data.hasPlaceDetail.parkingCnt }}대</p>
                </div>

                <div
                  v-if="
                    data.hasPlaceDetail && data.hasPlaceDetail.builtIn !== null
                  "
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">빌트인</span>
                  <p class="flex-1">{{ data.hasPlaceDetail.builtIn }}</p>
                </div>

                <div
                  v-if="
                    data.hasPlaceDetail &&
                      data.hasPlaceDetail.isElevator !== null
                  "
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">엘레베이터 여부</span>
                  <p class="flex-1">유</p>
                </div>

                <div
                  v-if="
                    data.hasPlaceDetail &&
                      data.hasPlaceDetail.direction !== null
                  "
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">방향</span>
                  <p class="flex-1">{{ data.hasPlaceDetail.direction }}</p>
                </div>

                <div
                  v-if="
                    data.hasPlaceDetail &&
                      data.hasPlaceDetail.heatingType !== null
                  "
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">난방방식</span>
                  <p class="flex-1">{{ data.hasPlaceDetail.heatingType }}</p>
                </div>

                <div
                  v-if="
                    data.hasPlaceDetail &&
                      data.hasPlaceDetail.completionYear !== null
                  "
                  class="item-content-sub flex flex-row items-center"
                >
                  <span class="flex-1">준공년도</span>
                  <p class="flex-1">
                    {{ data.hasPlaceDetail.completionYear }}년
                  </p>
                </div>
              </div>
            </div>

            <div class="item">
              <span>* 공간주소</span>
              <div class="item-content">
                {{ data.addr }}
              </div>
            </div>
          </div>
        </div>

        <div class="content-right-wrapper content-box flex flex-col">
          <ImageCarousel :images="images" />

          <div class="content-inner flex-1">
            <h3>{{ data.subTitle }}</h3>
            <p>{{ data.explanation }}</p>
          </div>

          <div class="map-container flex flex-col">
            <div class="map-address flex flex-row items-center justify-center">
              {{ data.addr }}
            </div>
            <NaverMap :draggable="false" :center="center" :markers="marker" />
          </div>
        </div>
      </div>

      <div
        v-if="recommends.length > 0"
        class="w-full recommend-wrapper flex flex-col items-center"
      >
        <h3>주변 추천공간</h3>

        <div class="w-full slide-wrapper">
          <vueper-slides
            class="no-shadow"
            :bullets="false"
            :autoplay="false"
            :arrowsOutside="true"
            prevent-y-scroll
            :dragging-distance="150"
            :gap="2"
            :visible-slides="visibleSlideCnt"
            :fixed-height="slideHeight"
          >
            <vueper-slide v-for="item in recommends" :key="item.id">
              <template #content>
                <div slot="slideContent">
                  <PlaceItem :data="item" />
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>

      <Chat
        v-if="userType !== 'interior'"
        :isOpen="isOpen"
        :onOpen="onChat"
        :target="target"
      />
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import PlaceSummaryInfo from "@/components/PlaceSummaryInfo.vue";
import NaverMap from "@/components/NaverMap.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import Chat from "@/components/Chat.vue";
import PlaceItem from "@/components/PlaceItem.vue";
import services from "@/services";
import _ from "lodash";
import utils from "@/utils";
import { mapState } from "vuex";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "PlaceDetail",
  components: {
    Layout,
    PlaceSummaryInfo,
    ImageCarousel,
    NaverMap,
    Chat,
    PlaceItem,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      data: {},
      offers: [],
      recommends: [],
      isOpen: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.getData();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async getData() {
      const [data, offers] = await Promise.all([
        services.placeDetail(this.placeId),
        services.placeJoinOfferList({ placeId: this.placeId }),
      ]);
      this.data = data;
      this.offers = offers;
      await this.getRecommend();
    },
    async getRecommend() {
      const { latitude, longitude } = this.data;
      if (latitude && longitude) {
        const { data } = await services.placeList({
          paginate: 9,
          lat: latitude,
          lon: longitude,
        });
        this.recommends = data.filter((d) => d.id !== this.placeId);
      }
    },
    onChat() {
      this.isOpen = !this.isOpen;
    },
    goToJoin() {
      this.$router.push({ name: "PlaceOffer", params: { id: this.data.id } });
    },
  },
  computed: {
    visibleSlideCnt: function() {
      if (this.windowWidth >= 1024) {
        return 4;
      } else if (this.windowWidth >= 768) {
        return 3;
      } else if (this.windowWidth >= 640) {
        return 2;
      } else {
        return 1;
      }
    },
    slideHeight: function() {
      if (this.windowWidth >= 640) {
        return '600px';
      } else {
        return '300px';
      }
    },
    minPrice: function() {
      const value = _(this.offers)
        .groupBy("id")
        .map((group) => _.minBy(group, "price"))
        .value();

      if (value.length > 0) {
        return value[0].price;
      }

      return null;
    },
    maxPrice: function() {
      const value = _(this.offers)
        .groupBy("id")
        .map((group) => _.maxBy(group, "price"))
        .value();

      if (value.length > 0) {
        return value[0].price;
      }

      return null;
    },
    utilities() {
      const { utilities: price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 0;
    },
    leasePrice() {
      const { leasePrice: price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 0;
    },
    images() {
      return utils.getPlaceImages(this.data);
    },
    center() {
      const { latitude, longitude } = this.data;
      if (latitude && longitude) {
        return {
          lat: latitude,
          lng: longitude,
        };
      }

      return null;
    },
    marker() {
      const { latitude, longitude } = this.data;
      if (latitude && longitude) {
        return [
          {
            lat: latitude,
            lng: longitude,
          },
        ];
      }

      return [];
    },
    target() {
      const { hasOwner } = this.data;
      if (hasOwner) {
        return hasOwner;
      }
      return null;
    },
    placeId() {
      const { id } = this.$route.params;
      return id;
    },
    leasableArea() {
      let area = 0;
      const { hasPlaceDetail, size } = this.data;
      if (hasPlaceDetail) {
        const { netLeasableArea, leaseableArea } = hasPlaceDetail;
        if (leaseableArea) {
          area = leaseableArea;
        } else if (netLeasableArea) {
          area = netLeasableArea;
        }
      } else if (size) {
        area = size;
      }

      return area;
    },
    ...mapState("user", ["userType"]),
  },
  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    },
    placeId() {
      this.getData();
    },
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-top: 55px;
  margin-bottom: 80px;
}

.content-left-wrapper {
  width: 443px;
  margin-right: 24px;
}

.content-right-wrapper {
  flex: 1;

  .content-inner {
    padding: 35px 35px 0 35px;

    h3 {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.52;
      letter-spacing: normal;
      text-align: left;
      color: #212121;
      margin-bottom: 35px;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #212121;
    }
  }

  .map-container {
    height: 455px;
    margin-top: 84px;

    .map-address {
      height: 48px;
      background-color: #f6f6f6;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.14px;
      text-align: left;
      color: #1e1d1d;
    }
  }
}

.content-box {
  border: 1px solid #c9c9c9;

  &.content-box-1 {
    padding-left: 38px;
    padding-right: 38px;
  }

  &.content-box-2 {
    margin-top: 21px;

    .header {
      padding-top: 18px;
      padding-left: 31px;
      padding-bottom: 14px;
      margin-bottom: 27px;
      border-bottom: 1px solid #c9c9c9;

      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.64px;
        text-align: left;
        color: #283cf0;
        margin-left: 9px;
      }
    }

    .item {
      margin-left: 35px;
      margin-right: 47px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
      margin-bottom: 25px;

      span {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.6px;
        text-align: left;
        color: #b1b1b1;
      }

      p {
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.44px;
        text-align: left;
        color: #1d1d1d;
      }
    }

    .button-group {
      height: 52px;
      padding-left: 35px;
      padding-right: 35px;
      margin-bottom: 17px;
    }

    button {
      height: 52px;
      background-color: #283cf0;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.7px;
      text-align: left;
      color: #fff;
    }
  }

  &.content-box-3 {
    margin-top: 23px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 30px;
    padding-bottom: 30px;

    .item {
      &:not(:last-child) {
        margin-bottom: 32px;
      }

      span {
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.68px;
        text-align: left;
        color: #333;
      }

      .item-content {
        margin-top: 11px;
        background-color: #f7f7f7;
        padding: 17px 25px 14px 25px;

        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: left;
        color: #333;

        .item-content-sub {
          &:not(:last-child) {
            margin-bottom: 23px;
          }

          span {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #767676;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.14px;
            text-align: left;
            color: #333;
          }
        }
      }
    }
  }
}

.recommend-wrapper {
  margin-bottom: 80px;

  h3 {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.56;
    letter-spacing: -1.2px;
    text-align: left;
    color: #000;

    margin-bottom: 44px;
  }
}

@media (max-width: 1023px) {
  .content-wrapper {
    flex-direction: column;
  }
  .content-left-wrapper {
    max-width: 443px;
    width: 100%;
    margin: 0 auto 25px;
  }
}

@media (max-width: 639px) {
  .content-right-wrapper {
    .content-inner {
      padding: 20px;
      padding-bottom: 0;
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 11px;
      }
    }
    .map-container {
      margin-top: 50px;
      height: 300px;
    }
  }
  .content-wrapper {
    margin-bottom: 40px;
  }
  .recommend-wrapper {
    h3 {
      font-size: 18px;
      margin-bottom: 30px;
    }
    .slide-wrapper {
      max-width: calc(100% - 20px - 20px);
    }
  }
}
</style>
