<template>
<div :class="[
  'w-full',
  'message-wrapper',
  'flex',
  'flex-row',
  'items-end',
  {
    'justify-end': isMy,
    'flex-row-reverse': isMy,
    isMy
  }]">
  <div class="flex flex-row items-start justify-start">
    <div v-if="!isMy" class="message-profile" />

    <div class="flex-1 flex flex-col justify-start items-start">
      <p v-if="!isMy" class="message-name">
        {{ otherJoiner.nickname }}
      </p>
      <div class="message-content break-all">
        {{ data.message }}
      </div>
    </div>
  </div>
  <p :class="['message-date', {'flex-1': isMy}]">
    {{ date }}
  </p>
</div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import {mapState} from 'vuex';

dayjs.locale('ko');

export default {
  name: 'Message',
  props: {
    data: Object,
    joiners: Array,
  },
  computed: {
    isMy() {
      return this.data.joinerId === this.myJoiner?.id
    },
    myJoiner() {
      return this.joiners.find(j => j.userId === this.userId);
    },
    otherJoiner() {
      const other = this.joiners.find(j => j.userId !== this.userId);
      if (other) {
        return other.hasUser;
      }
      return {image: '', nickname: ''};
    },
    date() {
      const {createdAt} = this.data;
      if (createdAt) {
        return dayjs(createdAt).format("A hh:mm");
      }
      return ''
    },
    ...mapState('user', ['userId']),
  },
}
</script>

<style scoped lang="scss">
.message-wrapper {
  margin-top: 15px;

  &:last-child {
    padding-bottom: 30px;
  }

  .message-profile {
    display: none;
    width: 40px;
    height: 40px;
    background-color: #d5d5d5;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 7px;
  }

  .message-name {
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.5px;
    text-align: left;
    color: #333;
    margin-bottom: 7px;
  }

  .message-content {
    padding: 8px 14px;
    border-radius: 29.2px;
    background-color: #ebebeb;

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.48px;
    text-align: left;
    color: #111;
  }

  .message-date {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.4px;
    text-align: right;
    color: #a2a2a2;
    margin-right: 7px;
    margin-left: 7px;
    margin-bottom: 3px;
    min-width: 50px;
  }

  &.isMy {
    .message-content {
      background-color: #c9e9ff;
    }
  }
}

@media (max-width: 639px) {
  .message-wrapper {
    .message-content {
      padding: 5px 10px;
      font-size: 10px;
    }
    .message-date {
      margin-right: 4px;
      margin-left: 4px;
      margin-bottom: 1px;
    }
    .message-name {
      font-size: 11px;
      margin-bottom: 4px;
    }
  }
}
</style>