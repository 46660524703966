var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-wrapper fixed"},[_c('div',{staticClass:"chat-float flex justify-center items-center",on:{"click":function($event){$event.stopPropagation();return _vm.onOpen.apply(null, arguments)}}},[_c('eva-icon',{attrs:{"name":"message-circle","width":_vm.chatIconSize,"height":_vm.chatIconSize,"fill":'#fff'}})],1),_c('div',{class:[
      'absolute',
      'chat-rooms-wrapper',
      'items-start',
      'justify-start',
      'flex',
      'flex-col',
      'ease-in-out',
      'duration-300',
      'transition-transform',
      'transform',
      'origin-bottom-left',
      { 'scale-100': _vm.isOpen, 'scale-0': !_vm.isOpen } ]},[(_vm.type === 'list')?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"w-full chat-rooms flex-1 flex flex-col items-start justify-end"},[_c('div',{staticClass:"chat-rooms-inner w-full"},_vm._l((_vm.rooms),function(item){return _c('ChatRoom',{key:item.id,attrs:{"onRoom":_vm.onRoom,"data":item}})}),1)])]):_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"chat-room-header w-full flex justify-center items-center relative"},[_vm._v(" "+_vm._s(_vm.roomTitle)+" "),(this.target && this.userId === this.target.id)?_c('button',{staticClass:"chat-room-header-back-btn",on:{"click":_vm.redirectListPage}},[_c('eva-icon',{attrs:{"name":"arrow-back-outline","width":20,"height":20,"fill":'#000000'}})],1):_vm._e()]),_c('div',{staticClass:"overflow-y-scroll overflow-x-hidden overscroll-y-none w-full chat-contents flex-1 flex flex-col flex-nowrap items-start justify-end"},[_c('div',{staticClass:"chat-contents-inner"},_vm._l((_vm.messages),function(item){return _c('Message',{key:item.id,attrs:{"joiners":_vm.joiners,"data":item}})}),1)]),_c('div',{staticClass:"w-full chat-input-wrapper flex flex-row items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"flex-1",attrs:{"type":"text","placeholder":"메세지를 입력하세요."},domProps:{"value":(_vm.message)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendMessage.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('button',{staticClass:"flex justify-center items-center",on:{"click":_vm.sendMessage}},[_c('eva-icon',{attrs:{"name":"paper-plane","width":25,"height":25,"fill":'#283aef'}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }