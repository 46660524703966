<template>
  <div class="chat-wrapper fixed">
    <div
      @click.stop="onOpen"
      class="chat-float flex justify-center items-center"
    >
      <eva-icon 
        name="message-circle" 
        :width="chatIconSize" 
        :height="chatIconSize" 
        :fill="'#fff'" 
      />
    </div>

    <div
      :class="[
        'absolute',
        'chat-rooms-wrapper',
        'items-start',
        'justify-start',
        'flex',
        'flex-col',
        'ease-in-out',
        'duration-300',
        'transition-transform',
        'transform',
        'origin-bottom-left',
        { 'scale-100': isOpen, 'scale-0': !isOpen },
      ]"
    >
      <div v-if="type === 'list'" class="w-full">
        <div
          class="w-full chat-rooms flex-1 flex flex-col items-start justify-end"
        >
          <div class="chat-rooms-inner w-full">
            <ChatRoom
              v-for="item in rooms"
              :onRoom="onRoom"
              :key="item.id"
              :data="item"
            />
          </div>
        </div>
      </div>
      <div v-else class="w-full">
        <div class="chat-room-header w-full flex justify-center items-center relative">
          {{ roomTitle }}

          <button v-if="this.target && this.userId === this.target.id" 
            class="chat-room-header-back-btn"
            @click="redirectListPage"
          >
            <eva-icon
              name="arrow-back-outline"
              :width="20"
              :height="20"
              :fill="'#000000'"
            />
          </button>
        </div>
        <div
          class="overflow-y-scroll overflow-x-hidden overscroll-y-none w-full chat-contents flex-1 flex flex-col flex-nowrap items-start justify-end"
        >
          <div class="chat-contents-inner">
            <Message
              v-for="item in messages"
              :key="item.id"
              :joiners="joiners"
              :data="item"
            />
          </div>
        </div>
        <div class="w-full chat-input-wrapper flex flex-row items-center">
          <input
            v-model="message"
            type="text"
            class="flex-1"
            @keyup.enter="sendMessage"
            placeholder="메세지를 입력하세요."
          />
          <button @click="sendMessage" class="flex justify-center items-center">
            <eva-icon
              name="paper-plane"
              :width="25"
              :height="25"
              :fill="'#283aef'"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "@/components/Message.vue";
import ChatRoom from "@/components/ChatRoom.vue";
import services from "@/services";
import { mapState } from "vuex";
import dayjs from "dayjs";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

export default {
  name: "Chat",
  components: {
    Message,
    ChatRoom,
  },
  props: {
    target: Object,
    placeId: Number,
    interiorOfferId: Number,
    isOpen: {
      type: Boolean,
      default: false,
    },
    onOpen: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      message: "",
      room: null,
      messages: [],
      rooms: [],
      targetId: null,
      type: "list",
      echo: null,
      isConnected: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.prepare();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async prepare() {
      if (!this.target) {
        return "";
      }

      if (this.userId === this.target.id) {
        this.type = "list";
        await this.getChatRoomList();
      } else {
        this.type = "room";
        this.targetId = this.target.id;
        await this.createChatRoom();
        await this.getMessages();
      }
    },
    async createChatRoom() {
      const { success, data } = await services.chatRoomCreate({
        targetId: this.targetId,
      });
      if (success) {
        this.room = data;
        this.connect();
      }
    },
    async getChatRoomList() {
      const data = await services.chatRoomList({
        joinerId: this.userId,
        isIncludeMessage: 1,
      });

      const tempRoom = [];

      data.map(function(value) {
        let isMessageExist = false;

        if (value && value.hasChatJoiners) {
          value.hasChatJoiners.forEach(element => {
            if (element.hasChatMessagesCount > 0) {
              isMessageExist = true;
              return;
            }
          });

          if (isMessageExist) {
            tempRoom.push(value);
          }
        }
      });

      this.rooms = tempRoom;
    },
    async sendMessage() {
      const message = this.message;
      this.message = "";
      if (!this.room) {
        return;
      }
      const { hasChatJoiners } = this.room;
      const myJoiner = hasChatJoiners.find((j) => j.userId === this.userId);

      const data = await services.chatMessageCreate({
        message,
        joinerId: myJoiner.id,
        sendTime: dayjs().unix(),
      });
      this.addMessage(data);
    },
    async getMessages() {
      console.log('getMessages');
      if (!this.room) {
        return;
      }

      const data = await services.chatMessageList({
        roomId: this.room.id,
      });
      this.messages = data.reverse();
    },
    async onRoom(room) {
      this.type = "room";
      const { hasChatJoiners } = room;
      const other = hasChatJoiners.find((j) => j.userId !== this.userId);
      this.targetId = other.userId;
      await this.createChatRoom();
      await this.getMessages();
    },
    async addMessage(message) {
      if (message) {
        await this.getMessages();
      }
    },
    scrollToElement() {
      setTimeout(() => {
        console.log('scrollToElement');
        const msgList = this.$el.getElementsByClassName("message-wrapper");
        const msgCnt = this.messages.length;
        const lastMsg = msgList.item(msgCnt - 1);

        if (lastMsg) {
          lastMsg.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    },
    connect() {
      if (!this.echo) {
        console.log(Pusher);
        this.echo = new Echo({
          broadcaster: "pusher",
          key: "bbbbe47c0227c6bf6e61",
          wsHost: (process.env.VUE_APP_IS_LOCAL_SERVER == 1 ? process.env.VUE_APP_BASEURL_LOCAL : process.env.VUE_APP_BASEURL_SERVER),
          wsPort: 6001,
          forceTLS: false,
        });
        this.echo.connector.pusher.connection.bind("connected", () => {
          this.isConnected = true;
        });
        this.echo.connector.pusher.connection.bind("disconnected", () => {
          this.isConnected = false;
        });
      }
      this.echo.connector.pusher.connect();
    },
    bindChannels() {
      console.log("bindChannels", this.room);
      if (!this.room) {
        return "";
      }
      this.echo
        .channel(this.room.channelName)
        .listen("MessageSend", (payload) => {
          const { message } = payload;
          this.addMessage(message);
        });
    },
    redirectListPage() {
      this.type = "list";
    },
  },
  computed: {
    chatIconSize: function() {
      if (this.windowWidth >= 1280) {
        return 40;
      } else {
        return 30;
      }
    },
    joiners() {
      if (this.room) {
        const { hasChatJoiners } = this.room;
        return hasChatJoiners;
      }
      return [];
    },
    myJoiner() {
      return this.joiners.find((j) => j.userId === this.userId);
    },
    otherJoiner() {
      return this.joiners.find((j) => j.userId !== this.userId);
    },
    roomTitle() {
      if (this.otherJoiner) {
        return `${this.otherJoiner.hasUser.nickname}과의 거래`;
      }
      return "";
    },
    ...mapState("user", ["userType", "userId"]),
  },
  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    },
    target() {
      this.prepare();
    },
    isConnected(newValue) {
      if (newValue) {
        this.bindChannels();
      }
    },
    messages(newValue) {
      if (newValue.length > 0) {
        this.scrollToElement();
      }
    }
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
.chat-wrapper {
  z-index: 10000;
  bottom: 40px;
  left: 40px;
}

.chat-float {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #283aef;
  cursor: pointer;
}

.chat-rooms-wrapper {
  left: 35px;
  bottom: 70px;
  width: 370px;
  height: 670px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 0 3px #fafafa, inset 0 0 0 1px #c7c7c7;
  overflow: auto;

  .chat-room-header {
    height: 55px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
    padding: 0 40px;

    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.6px;
    text-align: center;
    color: #000;

    .chat-room-header-back-btn {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 40px;
    }
  }

  .chat-input-wrapper {
    height: 55px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;

    input {
      height: 40px;
      padding: 12px 20px;
      border-radius: 17px;
      margin-left: 20px;
      background-color: #f1f2f6;

      font-size: 11.5px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.23px;
      text-align: left;
    }

    button {
      width: 50px;
      height: 40px;
    }
  }

  .chat-contents-inner {
    width: 100%;
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 20px;
    height: 560px;
  }
}

@media (max-width: 1279px) {
  .chat-wrapper {
    bottom: 20px;
    left: 20px;
  }
  .chat-float {
    width: 50px;
    height: 50px;
    i {
      width: 30px;
      height: 30px;
    }
  }
  .chat-rooms-wrapper {
    left: 25px;
    bottom: 50px;
    max-width: calc(100vw - 70px);
    width: 370px;
    height: calc(100vh - 70px - 10px);
    &>div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .chat-contents {
      height: 100%;
    }
    .chat-contents-inner {
      height: 100%;
      overflow: auto;
    }
  }
}

@media (max-width: 639px) {
  .chat-rooms-wrapper {
    max-width: calc(100vw - 110px);
    left: 0px;
    .chat-room-header {
      font-size: 11px;
      height: 30px;
    }
    .chat-input-wrapper {
      height: 35px;
      input {
        padding: 5px 10px;
        font-size: 10px;
        height: 25px;
        margin-left: 10px;
      }
      button {
        width: 30px;
        height: 20px;
      }
    }
    .chat-contents-inner {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }
  }
}
</style>
