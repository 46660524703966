<template>
<div @click.stop="() => onRoom(data)" class="chat-room-wrapper w-full flex flex-row items-center">
  <div class="chat-room-profile"></div>
  <div class="chat-room-content flex flex-col flex-1 justify-center">
    <div class="chat-room-name">{{ name }}</div>
    <div class="chat-room-message break-all">{{ lastMessage }}</div>
  </div>
  <div>
    <div class="chat-room-date">{{ date }}</div>
  </div>
</div>
</template>

<script>
import {mapState} from 'vuex';
import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

export default {
  name: 'ChatRoom',
  props: {
    data: Object,
    onRoom: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    joiner() {
      const {hasChatJoiners} = this.data;
      if (hasChatJoiners.length > 0) {
        return hasChatJoiners.find(j => j.userId !== this.userId);
      }
      return null;
    },
    name() {
      if (this.joiner) {
        const {hasUser} = this.joiner;
        if (hasUser) {
          return hasUser.nickname;
        }
      }
      return '';
    },
    lastMessage() {
      const {hasChatJoiners} = this.data;
      const message = _.maxBy(
        _.flatten(
          hasChatJoiners.map(j => j.hasChatMessages)
        ),
        x => x.sendTime
      );
      if (message) {
        return message.message;
      }
      return '';
    },
    date() {
      const {createdAt} = this.data;
      if (createdAt) {
        return dayjs(createdAt).format("A hh:mm");
      }
      return ''
    },
    ...mapState('user', ['userId']),
  },
}
</script>

<style scoped lang="scss">
.chat-room-wrapper {
  height: 80px;
  padding-left: 18px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;

  .chat-room-profile {
    display: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #d5d5d5;
    margin-right: 14px;
  }

  .chat-room-name {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.52px;
    text-align: left;
    color: #000;
  }

  .chat-room-message {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.52px;
    text-align: left;
    color: #8b8b8b;
  }

  .chat-room-date {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.48px;
    text-align: left;
    color: #707070;
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media (max-width: 639px) {
  .chat-room-wrapper {
    padding-left: 10px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    .chat-room-name {
      font-size: 10px;
    }
    .chat-room-message {
      font-size: 11px;
    }
    .chat-room-date {
      font-size: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>