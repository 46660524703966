var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'w-full',
  'message-wrapper',
  'flex',
  'flex-row',
  'items-end',
  {
    'justify-end': _vm.isMy,
    'flex-row-reverse': _vm.isMy,
    isMy: _vm.isMy
  }]},[_c('div',{staticClass:"flex flex-row items-start justify-start"},[(!_vm.isMy)?_c('div',{staticClass:"message-profile"}):_vm._e(),_c('div',{staticClass:"flex-1 flex flex-col justify-start items-start"},[(!_vm.isMy)?_c('p',{staticClass:"message-name"},[_vm._v(" "+_vm._s(_vm.otherJoiner.nickname)+" ")]):_vm._e(),_c('div',{staticClass:"message-content break-all"},[_vm._v(" "+_vm._s(_vm.data.message)+" ")])])]),_c('p',{class:['message-date', {'flex-1': _vm.isMy}]},[_vm._v(" "+_vm._s(_vm.date)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }